
.containered {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
}

.containered .hero_home_page {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100vh;
    padding: 80px 0 0 100px;
}

.main--img {
    background-repeat: no-repeat;
}
.main--content{
    width: 50%;
    display: flex;
    flex-direction: column;
    margin-top: -100px;
}
.main--img{
    width: 50%;
    
}
.main--img > img {
    /* max-width: 289px !important; */
    width: 100%;
    max-width: 550px;
    margin-right: -40px ;
}

.headline {
    font-style: normal;
    font-weight: 600;
    font-size: 70px;
    letter-spacing: 0.5px;
    line-height: 80px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #18191F;
    margin: 0;
    padding: 0;
}

.description {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 35px;
    font-feature-settings: 'salt' on, 'liga' off;
    color: #18191F;
    max-width: 600;

}



@media only screen and (max-width: 1200px) {
    .headline {
        max-width: 360px;
        font-size: 38px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;

        line-height: 50px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #18191F;

    }

    .description {

        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 19px;
        /* or 158% */

        font-feature-settings: 'salt' on, 'liga' off;

        /* Text / Gray 900 */

        color: #18191F;

    }

    .showDesktop{
        display: block ;
    }
    .showMobile{
        display: none;
    }
}

@media only screen and (max-width: 900px) {
    .showDesktop{
        display: none;
    }
    .showMobile{
        display: block;
    }
    .containered{
        height: auto;
    }

   .main--content{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 120px;
    }
    .main--img{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .main--img > img {
        /* max-width: 289px !important; */
        width: 100%;
        max-width: 320px;
        margin-right: -40px ;
    }

    .navigation {
        display: none;
        margin: 0px !important;
    }

    .containered .hero_home_page {
        background-image: none !important;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        width: 100%;
        height: auto;
        padding-right: 0;
        padding: 20px;

    }

    
    .headline {
        font-size: 35px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        line-height: 50px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #18191F;

    }

    .description {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        font-feature-settings: 'salt' on, 'liga' off;
        color: #18191F;

    }
}
