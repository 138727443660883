.edit_card_submit{
    width: 100%;
height: 100%;
/* border: 1px solid red; */
padding: 20px;
height: 180px;
color: #848199;
}
.edit_card_submit textarea{
width: 100%;
outline: none; 
border: none;
font-size: 13px;
}