
.navbar_container{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 50;
}
.logo{
    cursor: pointer;
    width: 200px;
}

.navbar_container header {
    padding: 0px;
    top: 0;
    width: 100%;
    padding: 10px 70px;
}

.header-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

}
.loginbtn{
    cursor: pointer;
}

.navigation {
    flex-grow: 1;
    margin-left: 40px;
}

.navigation--item {
    text-decoration: none;
    color: #18191F;
    margin-left: 40px;
    cursor: pointer;
    user-select: none;

}

.navigation--item__active {
    color: #FF7900;
    cursor: pointer;
    user-select: none;
}

.navigation--button {
    display: flex;
    gap: 10px;
}

.navbar_container {
    background-color: #FFFFFF;
    /* box-shadow: 0px 1px 0px #E5E9F2; */
}

.navbar_body{
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    flex-wrap: wrap;
    padding: 10px 40px;
}

.navbar_menu{
    display: flex;
    list-style: none;
}

.navbar_body li{
  padding: 0 15px;
}

.navbar_login_signup_container{
    display: flex;
    gap: 10px;
}
.hambugerr{
    display: none;
}
.sideBarDrawer{
    /* width: 100%;
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: -10px;
    padding: 0 20px;
    justify-content: space-between; */
}

.linksContLink {
    text-decoration: none;
    /* font-size: 18px; */
    font-size: 1rem;
    font-weight: 500;
    line-height: 19px;
    color: #141811;
    cursor: pointer;
    user-select: none;
}
.linksContLinkActive {
    text-decoration: none;
    /* font-size: 15px; */
    font-size: 1rem;

    font-weight: 500;
    line-height: 19px;
    color: var(--main);
    cursor: pointer;
    user-select: none;
}
@media only screen and (max-width: 900px) {
    .logo{
        cursor: pointer;
        width: 120px;
    }
    .navbar_menu_container{
        display: none;
    }
    .navbar_body ul{
        display: none;
    }
    .navbar_body{
        display: flex;
        justify-content: center;
    
    }

}
@media only screen and (max-width: 480px) {
    .logo{
        cursor: pointer;
        width: 130px;
    }
    .header-section {
        justify-content: space-between;
        align-items: center;
    }

}
@media only screen and (max-width: 1200px) {

    .navigation {
        display: none;
        margin: 0px !important;
    }
    .navbar_container header {
        padding: 10px 20px;
    }

    .hambugerr{
        display: block;
        /* position: fixed; */
        /* top: 80px; */
        /* left: 0px; */
        /* z-index: 60; */

    }
}

@media only screen and (max-width: 300px) {

.navigation--button {
    display: block;
}

}