@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');

:root{
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  --main: #FF7900;
  --sub-main: #FFEFE0; 
  --black: #0B0500;
  --light-black: #333333;
  --gray: #FAFAFA;
}
.swiper-pagination-bullet {
    /* border: 1px solid white !important; */
    
}

.swiper-pagination-bullet-active {
  background-color: white !important;
}


*{
  box-sizing: border-box;
  font: inherit;
  color: inherit;  
  /* margin: 0;
  padding: 0; */
}
html{
  scroll-behavior: smooth;
}
body {
  font-family: 'Inter', sans-serif;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
  }
div{
    box-sizing: border-box;
  }
.floatingNav {
    width: calc(100vh - 100px); 
    border-radius: 2px;
    box-shadow: 0px 1px 10px #999;
  }

  .Mui-selected {
    background-color: var(--black) !important;
  }
  .display_flex{
    width: 100%;
    display: flex;
}
  .space_between{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.justify_center{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  scroll-behavior: smooth;
}