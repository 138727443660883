
  
  .sidenav__top__logo{
    cursor: pointer;
    margin-top: 6px;
    width: 200px;
  }
  .sidenav {

    grid-area: sidenav;
   display: flex;
   flex-direction: column;
   height: 100%;
   /* height: 100vh; */
   width: 240px;
   position: fixed;
   /* padding-bottom: 100px; */
   overflow-y: auto;
   transform: translateX(-245px);
   transition: all .6s ease-in-out;
   box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.16), 0 0 0 1px rgba(0, 0, 0, 0.08);
   z-index: 20; 
   background-color: #1A1F29;
 }
 
 .sidenav.active {
   transform: translateX(0);
 }
  
  .sidenav__close-icon {
    position: absolute;
    visibility: hidden;
    top: 8px;
    right: 12px;
    cursor: pointer;
    font-size: 20px;
    color: #ddd;
  }
  
  
  .sidenav__close-icon-{
    position: fixed;
    left: 210px;
    top: 18px;
    color: #ffffff;
    z-index: 20;
  }
  
  .sidenav__list {
    padding: 0;
    margin-top: 85px;
    list-style-type: none;
  }
  
  .sidenav__list-item {
    padding: 20px 20px 20px 40px;
    color: #ddd;
  }
  
  .sidenav__list-item:hover {
    background-color: rgba(255, 255, 255, 0.2);
    cursor: pointer;
  }
  
 
  
  .disBtn{
    color: var(--main);
    border: var(--main) 1px solid;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    padding: 3px;
    width: 100px;
    justify-content: center;
    cursor: pointer;
}
.ediBtn{
    color: var(--main);
    border: var(--main) 1px solid;
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 3px;
    padding: 3px;
    width: 100px;
    justify-content: center;
    cursor: pointer;
}
 
  


  @media only screen and (min-width: 46.875em) {
   
    .sidenav {
      position: relative;
      transform: translateX(0);
      
    }
  
    .sidenav__close-icon {
      visibility: hidden;
    }
  
    .sidenav__close-icon-{
        visibility: hidden;
    }
  
  }
  
