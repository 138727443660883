.container_contact{
    padding: 100px 80px;
}
@media screen and (max-width: 900px) {
  .container_contact{
    padding: 30px;
    }  
    .privacy_container{

        padding: 30px !important;

    }


}


.privacy_title{
    text-align: center;
    margin-top: 30px;
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;

}


.privacy_date{
    font-weight: bold !important;
    font-size: 21px;
    margin-bottom: 20px;
}

.privacy_container{

    width: 100%;
    align-items: center;
    padding: 50px 150px;
 

}

.privacy_text_sub{
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
}

.general_title{
    font-size: 30px;
    margin-bottom: 30px;
}

.general_main_content{
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
}

ul li{
    margin-bottom: 10px;
}

ol li{
    margin-bottom: 10px;
}

li > span {
    /* font-size: 10px; */
    font-weight: bold;
}