body {
  margin: 0;
  padding: 0;
  /* color: #fff; */
  font-family: 'Open Sans', Helvetica, sans-serif;
  box-sizing: border-box;
}


.logout__text{
  color: #fff;
}

/* Assign grid instructions to our parent grid container, mobile-first (hide the sidenav) */
.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    'section'
    'section'
    'section';
  height: 100%;
}

.menu-icon {
  position: fixed; /* Needs to stay visible for all mobile scrolling */
  display: flex;
  top: 20px;
  left: 20px;
  align-items: center;
  justify-content: center;
  background-color: #DADAE3;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  padding: 12px;
}

/* Give every child element its grid name */
.header {
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: #FFFFFF;
}

/* Make room for the menu icon on mobile */
.header__search {
  margin-left: 42px;
}



.sidenav__close-icon {
  position: absolute;
  visibility: hidden;
  top: 8px;
  right: 12px;
  cursor: pointer;
  font-size: 20px;
  color: #ddd;
}


.sidenav__close-icon-{
  position: fixed;
  left: 210px;
  top: 20px;
}

.sidenav__list {
  padding: 0;
  margin-top: 85px;
  list-style-type: none;
}

.sidenav__list-item {
  padding: 20px 20px 20px 40px;
  color: #ddd;
}

.sidenav__list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}

.main {
  grid-area: section;
  background-color: #FFFFFF;
  padding: 10px 30px;
}

.main-header {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  padding: 20px;
  height: 150px;
  background-color: lime;
  color: slategray;
}

.main-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-auto-rows: 94px;
  grid-gap: 20px;
  gap: 20px;
  margin: 20px;
}

.overviewcard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #d3d3;
}

.main-cards {
  column-count: 1;
  column-gap: 20px;
  margin: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #82bef6;
  margin-bottom: 20px;
  -webkit-column-break-inside: avoid;
  padding: 24px;
  box-sizing: border-box;
}

/* Force varying heights to simulate dynamic content */
.card:first-child {
  height: 485px;
}

.card:nth-child(2) {
  height: 200px;
}

.card:nth-child(3) {
  height: 265px;
}

.footer {
  grid-area: footer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background-color: khaki;
}

.role__logout{
  padding: 20px;
  display: flex;
  align-items: center;
  bottom: 0;
  gap: 10px;
  cursor: pointer;
  user-select: none;
  position: absolute;
}


.main_name{
font-family:  'Inter', sans-serif;;
font-style: normal;
font-weight: 500;
font-size: 24px;
letter-spacing: -0.5px;
color: rgba(24, 24, 25, 0.9);
flex: none;
order: 0;
flex-grow: 0;
margin-bottom: 2px;
margin: 0;
}

.main_name_edit{
  font-family:  'Inter', sans-serif;;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  letter-spacing: -0.5px;
  color: rgba(24, 24, 25, 0.9);
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0;
  margin-bottom: 20px;

  padding: 30px;
  
  }

.main_name_role{
font-family:  'Inter', sans-serif;;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 120%;
/* identical to box height, or 17px */
letter-spacing: -0.5px;
color: #848199;
opacity: 0.4;
margin-top: 0;
}
.div_cancel{
  display: flex;
  gap: 3px;
  cursor: pointer;
  align-items: center;
  color: maroon;
}


.main__name__btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 0;

}

.memorialbtn{
  background: #FF7900;
  border-radius: 8px;
  padding: 11px 12px;
 
  outline: none;
  border: 1px solid #FF7900;
display: flex;
gap: 5px;
cursor: pointer;
}

.memorial__text{
  font-family:  'Inter', sans-serif;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.3px;
  cursor: pointer;

}

.memorial__icon{
  color: #FFFFFF;
}

.memorial__cards{
  /* display: flex;
  justify-content: space-between;
  flex-wrap: wrap; */
  /* gap: 50px; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
  grid-gap: 20px;
  gap: 20px;


}

.memorial__cards > div:nth-child(1){
  background: #F2E7FC;

  
  border: 0.745206px solid #E6E7E9;
  border-radius: 5.96164px;
  /* width: 260px;
height: 135px; */
padding: 10px 40px;
/* width: 260px; */
}

.memorial__cards > div:nth-child(2){
  background: rgba(76, 175, 80, 0.15);
  /* ui-base / 300 */
  
  border: 0.745206px solid #E6E7E9;
  border-radius: 5.96164px;
  /* width: 260px;
  height: 135px; */
  padding: 10px 40px;
  /* width: 260px; */


}

.memorial__cards > div:nth-child(3){
  background: rgba(225, 245, 253, 0.5);
  /* ui-base / 300 */
  
  border: 0.745206px solid #E6E7E9;
  border-radius: 5.96164px;
   /* width: 260px;
  height: 135px; */
  padding: 10px 40px;
  /* width: 260px; */
   
}


.memorial__cards > div:nth-child(4){
  background: rgba(255, 239, 224, 0.5);
/* ui-base / 300 */

border: 0.745206px solid #E6E7E9;
border-radius: 5.96164px;
/* width: 260px;
height: 135px;  */
padding: 10px 40px;
/* width: 260px; */
}


.memorials__title{
display: flex;
margin-top: 50px;
}

.memorial_tag{
font-family: 'Inter', sans-serif;
font-style: normal;
font-weight: 500;
font-size: 30px;
line-height: 36px;
color: #333333;
margin: 0;
margin-bottom: 3px;

}

.memorial_text{
  font-family: 'Inter', sans-serif;
  font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 24px;

/* Black */

color: #333333;

opacity: 0.4;

}


/* Non-mobile styles, 750px breakpoint */
@media only screen and (min-width: 46.875em) {
  /* Show the sidenav */
  .grid-container {
    grid-template-columns: 240px 1fr;
    grid-template-areas:
      "sidenav section"
      "sidenav section"
      "sidenav section";
  }

  .header__search {
    margin-left: 0;
  }

  

  .sidenav__close-icon {
    visibility: hidden;
  }

  .sidenav__close-icon-{
      visibility: hidden;
  }

 

}

/* Medium screens breakpoint (1050px) */
@media only screen and (min-width: 65.625em) {
  /* Break out main cards into two columns */
  .main-cards {
    column-count: 2;
  }
}

@media screen and (max-width : 749px) {
  .main_memorial{
    margin-top: -10px ;
  }
  .canceler{
    display: none;
  }

  .main__tag{
    /* margin-top: 20px; */
    width: 100%;
    display: flex;

  }
  
  .main__name__btn{
    gap: 7px;
    /* margin-bottom: 20px; */
    align-items: flex-end;
    /* margin-top: 40px; */
    flex-direction: column-reverse;
  
  }
  /* .main_name{
    padding: 17px 0 0 40px;
  } */
  .main_name_edit{
    padding: 17px 0 0 40px;
  }
}

@media screen and (max-height:680px) {
  .role__logout {
    position: static;
  }
}