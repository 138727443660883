.empty__memorial__title{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #979797;
    margin-top: 0;
    text-align: center;
    }

.empty_memorial{
    display: flex;
    justify-content: center;
   padding: 50px;
}

.empty__memorial_main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}