.preservelegacies{
    /* display: flex; */
    display: grid;
    justify-content: center;
    grid-template-columns: 1fr 1fr;
    grid-gap: 100px;
    gap: 100px;
    margin-bottom: 50px;
    padding: 65px 150px
  
}

.phone-mockup{
    max-width:100%;
    height:auto;
}

.legacies-title{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 54px;
/* or 135% */

font-feature-settings: 'salt' on, 'liga' off;

/* Text / Gray 900 */

color: #18191F;
width: 444px;
/* height: 108px; */
margin-top: 0px;
}

.legacies-paragraph{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 32px;
/* or 178% */

font-feature-settings: 'salt' on, 'liga' off;

/* Text / Gray 900 */

color: #18191F;
width: 444px;
/* height: 384px; */
}




@media only screen and (max-width: 900px) {


.preservelegacies{
    /* display:none; */
    /* flex-direction: column; */
    align-items: center;
    /* margin-top: 20px; */
    padding: 30px;
    margin-bottom: 0px;

    grid-template-columns: 1fr;
    grid-gap: 10px;
}

.legacies-title{
    font-size: 30px;
    text-align: center;

    width: auto;
}

.legacies-paragraph{
    font-size: 14.1024px;
    width: auto;
}




.preservelegacies{
    margin-bottom: 10px;
}


}


@media only screen and (min-width: 900px) {



}
