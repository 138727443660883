
.fourofour_container{
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}

.fourofourname{
   margin-top: 200px !important;
}


.toflex{
    display: flex;
    align-items: center;
    justify-content: center;

}

.top_bottom{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.twobtns{
    display: flex;
    justify-content: center;
    gap: 20px;
}

.fourofourtext{
font-family: 'Nunito', sans-serif;
font-style: normal;
font-weight: 700;
font-size: 60px;
color: #818C8B;
margin: 0;
}

.fourtext{
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    
    /* Primary foreground color */
    
    color: #2D4642;
    width: 538px;
    height: 48px;
}


.homebtn{
    background: #FF7900;
border-radius: 5px;
color: #FFFFFF;
font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
font-size: 14px;
padding: 10px;
border: #FF7900;
}

.contactbtn{
    background: #FFFFFF;
    border: 1px solid #C6CECD;
border-radius: 5px;
    color: #FF7900;
    font-family: 'Inter', sans-serif;
        font-style: normal;
        font-weight: 500;
    font-size: 14px;
    padding: 10px;
  
}






@media only screen and (max-width: 900px) {

    .toflex{
       flex-direction: column;
       justify-content: center;
       align-items: center;
       padding: 20px;
    }

    .left_logo > img{
        width: 100%;
  height: 300px;
  object-fit: cover;
  object-position: bottom;
    }

    /* .top_bottom{
    display: none;
    } */



    .fourtext{
       
        width: auto;
        height: auto;
    }
    


}