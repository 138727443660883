.signup_container{
    width: 100%;
    height: 100vh;
    display: flex;
    background-color: var(--main);
}
.signup_left{
    flex: 3;
    display: flex;
    gap: 30px;
    /* align-items: flex-end; */
    padding: 50px 20px;
    position: relative;
    flex-direction: column;
}
.error_label{
    font-size: 12px;
    color: rgb(183, 7, 7);
    margin-top: 3px;
}
.object_svg{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 250px;
}

.signup_textCont h1{
    font-size: 72px;
    font-weight: 800;
    color: white;
}
.PhoneInput{
    margin-top: 15px;

}
.PhoneInputInput{
    outline: none !important;
    border: none;
    border-bottom: 1px solid rgb(139, 139, 139);
    padding: 5px;
}
.signup_right{
    flex: 4;
    display: flex;
    justify-content: flex-start;
    padding: 50px 20px;
    background-color: white;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    height: 100vh;
    overflow: scroll;
}
.signup_right_container{
    width: 100%;
    max-width: 700px;
}

.signup_head{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* margin-bottom: 25px; */
    width: 100%;
}
.signup_head h1{
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    margin: 0;
}
.signup_head p{
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    text-align: center;
    margin: 0;
}
.signup_head span{
    color: var(--main);
    cursor: pointer;
    user-select: none;
}
.signup_form_container{
    padding: 30px;
}
.signup_form_cont{
    padding: 30px 30px 0 30px;
}
.addMarginMobile{
    margin-bottom: 0;
}
.mobilelogo{
    cursor: pointer;
    width: 120px;
    margin-bottom: 20px;
}
@media screen and (max-width: 900px) {
    .signup_container{
        height: auto;
    }
    .signup_form_container{
        padding: 0;
        width: 100%;
    }
    .showDesktop{
        display: none;
    }
    .showMobile{
        display: block;
    }
    .signup_left{
        display: none;
    }
    .signup_right{
        flex: 1;
        justify-content: center;
        padding: 50px 20px;
        padding-top: 20px;
        border-radius: 0;
        height: auto;

    }
    .signup_head h1{
        font-size: 23px;
        font-weight: 600;
        line-height: 32px;
    }
    .signup_head p{
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
    }
    .addMarginMobile{
        margin-bottom: 20px;
    }
}