.safe_container{
    width: 100%;
    background-color: rgb(255, 255, 255);
}
.safe_body{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 150px;
}

.safe_body h1{
    font-size: 48px;
    font-weight: 500;
    line-height: 64px;
    text-align: left;
}

.secure_img{
    width: 400px;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.data_card{
    padding: 48px 80px;
    display: flex;
    align-items: center;
    gap: 80px;
}

.data_head{
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 1px;
    text-align: left;
    margin-bottom: 5px;
}
.data_desc{
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    
    text-align: left;
}
.showDesktop{
    display: block;
}
.showMobile{
    display: none;
}

@media screen and (max-width: 1200px) {
    .secure_img{
        width: 100%;
        height: 300px;
    }
    .data_card{
        padding: 48px 30px;
        display: flex;
        align-items: center;
        gap: 40px;
    }
    .safe_body{
        display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 50px;
    }
}

@media screen and (max-width : 800px) {
    .showDesktop{
        display: none;
    }
    .showMobile{
        display: block;
    }
    .safe_body{
        padding: 0px;
    }
    .secure_img{
        display: none;
    }
    .safe_body h1{
        font-size: 30px;
        text-align: center;
        font-weight: 600;
    }
    .data_card{
        padding: 35px 20px;
        gap: 30px;
    }
    .data_head{
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.5px;
    }
    .data_desc{
        font-size: 11px;
        font-weight: 300;
        line-height: 15px;
    }
    
}

@media only screen and (max-width: 900px) {

}