.textspan{
    font-family: 'Inter', sans-serif;
    font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 18px;


color: #848199;
/* padding: 30px; */

}

.memorialnamemane{
    font-family: 'Inter', sans-serif;
    font-style: normal;
font-weight: 500;
font-size: 13.6px;
line-height: 140%;
/* or 19px */

letter-spacing: -0.34px;

color: #333333;
}

.from, .actualdate{
    font-family: 'Inter', sans-serif;
    font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;
display: flex;
align-items: center;

/* text-muted */

color: #9CA0AC;

transform: rotate(-0.54deg);
}

.bottomname{
    font-family: 'Inter', sans-serif;
    font-style: normal;
font-weight: 700;
font-size: 15px;
margin: 0;
/* line-height: 18px;
display: flex;
align-items: center; */

color: #333333;

/* transform: rotate(-0.54deg); */
}

.maintext{
    font-family: 'Inter', sans-serif;
    font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 22px;
/* or 160% */


color: #333333;
}

.initialmain{
    font-size: 1em;
    width: 3em;
    height: 3em;
    line-height: 3em;
    text-align: center;
    border-radius: 50%;
    background: #000000;
    vertical-align: middle;
    /* margin-right: 1em; */
    color: #FFFFFF;
}
.storycontent{
    width: 100%;
}
.memorialhead{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: relative;
}

.left-head{
    display: flex;
    align-items: center;
    gap: 10px;
}

.topdate{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    gap: 20px;
}

.inmemorytag{
    display: flex;
    align-items: center;
    gap: 10px;
}

.tributememorialcard{
    background: #FFFFFF;
border-radius: 10px;
filter: drop-shadow(0px 1px 7px rgba(0, 0, 0, 0.15));

/* padding: 30px; */
margin-top: 20px;
margin-bottom: 10px;
}

.icon-img{
    width: 2em;
    height: 2em;
    border-radius: 50%;

    /* object-fit: cover */
}


.inmemorial{
    border-bottom:0.5px solid #C4C4C4;
    padding: 15px 30px 10px 30px ;
    z-index: 1;
}


.showme{
position: absolute;
/* right: 10px; */
left: 0;
z-index: 10;

}

.memorialtextandhead{
    padding: 30px;
    /* height: 400px; */
}
.editdeletehide{
    display: flex;
    flex-direction: column;
    /* padding: 20px 20px; */
    background: #FFFFFF;
box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.12);
border-radius: 5px;
width: 176px;
border: 0.5px solid #C4C4C4;



}

.editdeletehide > div{
    border-bottom: 0.5px solid #C4C4C4;
   

}

.edittext, .deletetext, .hidetext{
    margin-left: 20px;
}


.story-img{
    width: 250px;
   height:250px;
   object-fit:cover;
   object-position:50% 50%;
}

.stryimg{
    
}




.storiesimageandnot{
    display: flex;
    /* justify-content: center; */
    /* align-items: center; */
}

@media screen and (max-width : 420px) {
    .inmemorytag{
        display: flex;
        align-items: flex-start;
        gap: 3px;
        flex-direction: column;
    }
    
}

@media only screen and (max-width: 720px) {


    .story-img{
        width: 100%;
        height: 250px;
   object-fit: cover;
   object-position:50% 50%;
    }

    .storiesimageandnot{
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* align-items: center; */
    }


}