.faq_container{
    width: 100%;
    background-color: white;
}
.faq_body{
    padding: 100px 150px 150px 150px;
}
.faq_head{
    margin-bottom: 75px;
}
.faq_head h1{
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    
    text-align: center;
    margin: 0;
}
.faq_head p{
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    
    text-align: center;
    margin: 0;
}
.accord_body{
    /* display:flex; 
    flex-direction:column; 
    gap: 10px */
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    grid-gap: 10px;
    
}
.showDesktop{
    display: block;
}
.showMobile{
    display: none;
}
@media screen and (max-width : 900px) {
    .showDesktop{
        display: none;
    }
    .showMobile{
        display: block;
    }
    .faq_body{
        padding: 80px 10px;
    }
    .faq_head h1{
        font-size: 30px;
        line-height: 35px;
    }
    .faq_head p{
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        width: 100%;
    }
    .faq_head{
        margin-bottom: 20px;
    }
  
}