.about_container{
    width: 100%;
    background-color: rgb(255, 255, 255);
}
.about_body{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 150px;
    gap: 30px;
}
.about_head{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}
.about_head h1{
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    
    text-align: center;
    margin: 0;
}
.about_head p{
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    
    text-align: center;
    margin: 0;
}
.flexedCard{
    /* display: flex; */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    /* gap: 10px; */
    grid-gap: 10px;
}
.flexedCardReverse{
    /* display: flex; */
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 10px;
}
.flexText{
    flex: 1;
}
.flexText h2{
    font-size: 40px;
    font-weight: 500;
    line-height: 45px;
    
    margin: 0;
}
.flexText p{
    font-size: 18px;
    font-weight: 400;
    line-height: 32px;
    
}
.flexImage{
    flex: 1;
}
.flexImage img{
    width:100%; 
    max-width:540px; 
    max-height:540px;
    height:auto;
    border-radius: 10px;  
}
.showDesktop{
    display: block;
}
.showMobile{
    display: none;
}

@media screen and (max-width : 900px) {
    .showDesktop{
        display: none;
    }
    .showMobile{
        display: block;
    }
    .about_body{
        padding: 30px;
    }
    .about_head h1{
        font-size: 30px;
    }
    .about_head p{
        font-size: 12px;
        font-weight: 400;
    }
    .flexedCard{
        /* flex-direction: column-reverse; */
        grid-template-columns: 1fr ;
    }
    .flexedCardReverse{
        /* flex-direction: column; */
        grid-template-columns: 1fr ;
    }
    .flexImage h2{
        font-size: 24px;
        font-weight: 500;
        line-height: 32px;
        text-align: center;
        
    }
    .flexText h2{
        font-size: 24px;
        font-weight: 500;
        line-height: 30px;
        text-align: center;

    }
    .flexText p{
        font-size: 15px;
        font-weight: 400;
        line-height: 22px;
        
    }
   
}