.create_container{
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../../assets/BGMemory.png');
}
.create_body{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 65px 150px;
}
.create_head{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 75px;
}
.create_head h1{
    font-size: 40px;
    font-weight: 600;
    line-height: 50px;
    
    text-align: center;
    margin: 0;
    color: white;
}
.create_head p{
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
    
    width: 100%;
    max-width: 825px;
    text-align: center;
    margin: 0;
    color: white;
}
@media screen and (max-width : 550px) {
    .create_body{
        padding: 30px;
    }
    .create_head h1{
        font-size: 30px;
    }
    .create_head p{
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
        width: 100%;
        max-width: 100%;
    }
    .create_head{
        margin-bottom: 20px;
    }
  
}