.createMemoCont{
    width: 100%;
    padding: 20px 50px 50px 50px;
}
.setupText{
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #979797;
    margin-bottom: 20px;
}
.stepDown{
    margin-top: 80px;
    display: flex;
}
.create_input{
    /* display: flex;
    flex-direction: column; */
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    gap: 30px;
    width: 100%;
}

.create_input .input_name{
    outline: none;
    border: 1px solid #c1c2c9;
    color:#8F92A1;
    border-radius: 4px;
    padding: 17px 20px  ;
    width: 100%;
}

.create_text{
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    color: #181819;
    margin-bottom: 70px;
    max-width: 500px;
}
.create_text2{
    font-weight: 500;
    font-size: 20px;
    line-height: 35px;
    color: #181819;
    margin-bottom: 15px;
    max-width: 500px;
}

@media screen and (max-width : 900px) {
    .stepDown{
        display: none;
    }
    .createMemoCont{
        width: 100%;
        padding: 10px 20px 50px 20px;
    }
}