.overLayHeader{
  margin-top: 70px;
  width:100%; 
  height:300px;
  display:flex; 
  align-items:center; 
  justify-content:center;
  position:relative; 
  background-position:center;
  background-repeat:no-repeat;
  background-size:cover;
}
.overLayHeader h1{
  color:white;
  font-size: 50px; 
  font-weight: 600; 
  z-index: 2;
}

@media screen and (max-width: 550px) {
  .overLayHeader{
    width:100%; 
    height:250px;
  margin-top: 70px;

  }

  .overLayHeader h1{
    font-size: 30px;
  }
}